import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import Spinner from "./Spinner";

const buttonVariants = cva(
  [
    "inline-flex",
    "items-center",
    "justify-center",
    "gap-2", // Consistent gap for icons and spinner
    "whitespace-nowrap",
    "rounded",
    "font-semibold",
    "transition-[colors,box-shadow,outline-color,outline-width,outline-style,background-color] ease-in-out duration-75",
    "focus:z-10",
    // outöine is diasbled for now since it does not add to the design
    "focus:shadow-focus",
    "outline-none",
    "outline-0",
    "outline-offset-0",
    "py-2.5",
    "px-5",
    "text-sm",
    "leading-none",
    // no select-none
    "select-none",
  ],
  {
    variants: {
      appearance: {
        default: "",
        pressable: "",
        primary: "",
        minimal: "",
      },
      intent: {
        none: "",
        success: "",
        danger: "",
      },
      size: {
        small: ["h-7", "px-3", "text-xs", "gap-1.5"],
        medium: ["h-9", "px-5", "text-sm", "gap-2"],
        large: ["h-10", "px-6", "text-base", "gap-2.5"],
      },
      block: {
        true: "w-full",
        false: "",
      },
      disabled: {
        true: [
          "cursor-not-allowed",
          "shadow-none",
          "focus:ring-0",
          "focus:ring-offset-0",
          "opacity-60",
          "select-none",
          "pointer-events-none",
        ],
        false: "",
      },
      loading: {
        true: "opacity-60 cursor-not-allowed pointer-events-none select-none",
        false: "",
      },
    },
    compoundVariants: [
      // DEFAULT APPEARANCE
      {
        appearance: "default",
        intent: "none",
        className: [
          "ring",
          "ring-1",
          "ring-gray-200",
          "bg-white",
          "text-gray-900",
          "shadow-pressable",
          "hover:shadow-sm",
          "hover:bg-gray-100",
          "active:bg-gray-100",
          "dark:border-gray-700",
          "dark:bg-gray-800",
          "dark:text-gray-100",
          "dark:hover:bg-gray-700",
          "dark:active:bg-gray-600",
        ],
      },

      {
        appearance: "default",
        intent: "success",
        className: [
          "ring",
          "ring-1",
          "ring-green-600/70",
          "bg-white",
          "text-green-600",
          "hover:shadow-sm",
          "hover:bg-green-50",
          "focus:outline-green-600/60",
          "active:bg-green-100",
          "dark:border-green-900",
          "dark:bg-gray-800",
          "dark:text-green-400",
          "dark:hover:bg-green-900/30",
          "dark:active:bg-green-900/50",
        ],
      },
      {
        appearance: "default",
        intent: "danger",
        className: [
          "ring",
          "ring-1",
          "ring-red-600",
          "bg-white",
          "text-red-600",
          "hover:shadow-sm",
          "hover:bg-red-50",
          "focus:outline-red-600/60",
          "active:bg-red-100",
          "dark:border-red-900",
          "dark:bg-gray-800",
          "dark:text-red-400",
          "dark:hover:bg-red-900/30",
          "dark:active:bg-red-900/50",
        ],
      },
      // PRESSABLE APPEARANCE
      {
        appearance: "pressable",
        intent: "none",
        className: [
          "hover:!outline",
          "hover:!outline-1",
          "hover:!outline-gray-400",
          "bg-white",
          "shadow-pressable",
          "hover:shadow-pressableHover",
          "focus:shadow-focus",
          "active:!shadow-pressable",
          "dark:bg-gray-800",
          "dark:ring-gray-700",
          "dark:hover:ring-gray-700",
        ],
      },
      // PRIMARY APPEARANCE
      {
        appearance: "primary",
        intent: "none",
        className: [
          "bg-gray-900",
          "text-white",
          "hover:bg-gray-700",
          "focus:outline-gray-600/60",
          "active:bg-gray-700",
          "dark:bg-gray-100",
          "dark:text-gray-900",
          "dark:hover:bg-white",
          "dark:active:bg-gray-200",
        ],
      },
      {
        appearance: "primary",
        intent: "success",
        className: [
          "bg-green-600",
          "text-white",
          "hover:bg-green-700",
          "focus:shadow-focus",
          "focus:outline-green-600/60",
          "active:bg-green-800",
          "dark:bg-green-500",
          "dark:hover:bg-green-400",
          "dark:active:bg-green-300",
        ],
      },
      {
        appearance: "primary",
        intent: "danger",
        className: [
          "bg-red-600",
          "text-white",
          "hover:bg-red-700",
          "focus:outline-red-600/60",
          "active:bg-red-800",
          "dark:bg-red-500",
          "dark:hover:bg-red-400",
          "dark:active:bg-red-300",
        ],
      },
      // MINIMAL APPEARANCE
      {
        appearance: "minimal",
        intent: "none",
        className: [
          "text-gray-700",
          "hover:bg-gray-100",
          "focus:outline-gray-600/60",
          "active:bg-gray-200",
          "dark:text-gray-300",
          "dark:hover:bg-gray-800",
          "dark:active:bg-gray-700",
        ],
      },
      {
        appearance: "minimal",
        intent: "success",
        className: [
          "text-green-600",
          "hover:bg-green-50",
          "focus:outline-green-600/60",
          "active:bg-green-100",
          "dark:text-green-400",
          "dark:hover:bg-green-900/30",
          "dark:active:bg-green-900/50",
        ],
      },
      {
        appearance: "minimal",
        intent: "danger",
        className: [
          "text-red-600",
          "hover:bg-red-50",
          "focus:outline-red-600/60",
          "active:bg-red-100",
          "dark:text-red-400",
          "dark:hover:bg-red-900/30",
          "dark:active:bg-red-900/50",
        ],
      },
      // DISABLED STATES
      {
        appearance: "default",
        disabled: true,
        className: [
          "!bg-gray-100",
          "!text-gray-600",
          "!ring-gray-200",

          "!dark:bg-gray-800/50",
          "!dark:text-gray-500",
          "!dark:border-gray-700",
        ],
      },
      {
        appearance: "primary",
        disabled: true,
        className: [
          "!bg-gray-300",
          "!text-gray-600",
          "dark:bg-gray-800",
          "dark:text-gray-500",
        ],
      },
      {
        appearance: "minimal",
        disabled: true,
        className: ["!bg-transparent", "!text-gray-400", "dark:!text-gray-600"],
      },
      // LOADING STATES
      {
        loading: true,
        appearance: "default",
        intent: "none",
        className: "!text-transparent ring-gray-300 !dark:text-transparent",
      },
      {
        loading: true,
        appearance: "default",
        intent: "success",
        className:
          "!text-transparent dark:!text-transparent  active:!bg-white focus:!bg-white",
      },
      {
        loading: true,
        appearance: "default",
        intent: "danger",
        className: "!text-transparent !dark:text-transparent active:bg-red-100",
      },

      {
        loading: true,
        appearance: "primary",
        intent: "none",
        className: "!text-transparent !dark:text-transparent",
      },
      {
        loading: true,
        appearance: "primary",
        intent: "success",
        className: "!text-transparent !dark:text-transparent",
      },
      {
        loading: true,
        appearance: "primary",
        intent: "danger",
        className: "!text-transparent !dark:text-transparent",
      },

      {
        loading: true,
        appearance: "minimal",
        className:
          "!text-transparent bg-gray-100 ring ring-gray-200 ring-1 !dark:text-transparent",
      },
    ],
    defaultVariants: {
      appearance: "default",
      intent: "none",
      size: "medium",
      variant: "default",
      block: false,
      disabled: false,
      loading: false,
    },
  }
);

const getIconClasses = (size) => {
  return {
    small: "h-3 w-3",
    medium: "h-3.5 w-3.5",
    large: "h-6 w-6",
  }[size];
};
/**
 * Button component
 * @param children - [string] - Button text
 * @param loading - [boolean] - Loading state
 * @param disabled - [boolean] - Disabled state
 * @param block - [boolean] - Full width button
 * @param size - [small, default, large] - Button size
 * @param appearance - [default,pressable, primary, minimal] - Button variant
 * @param intent - [none, success, danger] - Button color
 * @param className - [string] - Additional classes
 * @param iconBefore - [ReactNode] - Icon before the text
 * @param iconAfter - [ReactNode] - Icon after the text
 * @param onClick - [function] - Click handler
 * @param props - [any] - Additional props
 */
const Button = ({
  children,
  loading = false,
  disabled = false,
  block = false,
  size = "medium",
  appearance = "default",
  intent = "none",
  className,
  iconBefore,
  iconAfter,
  onClick,
  ...props
}) => {
  // handle invalid props
  // style prop has to be an object
  if (props.style && typeof props.style !== "object") {
    console.error(
      "Button component: style prop has to be an object. It will be ignored."
    );
    delete props.style;
  }

  return (
    <button
      onClick={!disabled && !loading ? onClick : undefined}
      disabled={disabled || loading}
      className={clsx(
        buttonVariants({
          appearance,
          intent,
          size,
          block,
          disabled,
          loading,
        }),
        className,
        "relative"
      )}
      {...props}
    >
      {/* Loading Spinner - Absolutely positioned to prevent layout shifts */}
      {loading && (
        <span className="z-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Spinner appearance={appearance} intent={intent} />
        </span>
      )}

      {/* Icon Before */}
      {iconBefore && !loading && (
        <span className={getIconClasses(size)}>{iconBefore}</span>
      )}

      {/* Content */}
      <span className="inline-flex items-center">{children}</span>

      {/* Icon After */}
      {iconAfter && !loading && (
        <span className={getIconClasses(size)}>{iconAfter}</span>
      )}
    </button>
  );
};

export default Button;
